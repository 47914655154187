import { IContentTemplate } from "dev/components/contentContainer/ContentContainer";
import GlobalPopup from "dev/components/globalPopup/GlobalPopup";
import React, { memo, useContext } from "react";
import Routes from "Routes";
import "./Content.scss";
import Popup from "dev/components/popup/Popup";
import Button from "dev/components/button/Button";
import { UserContextState } from "providers/UserProvider";
import { TranslationContext } from "providers/TranslationProvider";
import Loader from "dev/components/loader/Loader";
import Logout from "dev/components/login/Logout";

const ContentTemplate = ({
  hostClassNames,
  isSelected,
  hideContent,
  contentOverlayRef,
  navLinks,
  openRegistrationForm,
  openLoginForm
}: IContentTemplate) => {
  const { isLoggedIn, loginInProgress, profile } = useContext(UserContextState);
  const trans = useContext(TranslationContext);

  return (
    <>
      <div className="layout-content-holder-bc">
        <div className="bet-on-lineups-sign-in">
          {loginInProgress ? (
            <Loader size={"small"} />
          ) : !isLoggedIn ? (
            <>
              <Button className="bet-on-lineup-button sign-in" onClick={openLoginForm}>
                {trans("sign in")}
              </Button>
              <Button className="bet-on-lineup-button register" onClick={openRegistrationForm}>
                {trans("register")}
              </Button>
            </>
          ) : (
            <>
              <span className="bet-on-lineup-money">
                {profile.calculatedBalanceRounded} {profile.currency_name}
              </span>
              <Logout>
                {logoutUser => (
                  <div>
                    <Button className="bet-on-lineup-button register" onClick={logoutUser}>
                      {trans("Logout")}
                    </Button>
                  </div>
                )}
              </Logout>
            </>
          )}
        </div>
        <Routes />
      </div>
      <GlobalPopup />
      <Popup />
    </>
  );
};

export default memo(ContentTemplate);
