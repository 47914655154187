import React from "react";
import "./Button.scss";

export enum ButtonType {
  BUTTON = "button",
  SUBMIT = "submit",
  RESET = "reset"
}

interface IPropTypes {
  type?: ButtonType;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  title?: string;
  onClick?: (event: React.MouseEvent) => void;
}

const Button: React.FunctionComponent<IPropTypes> = ({ type, className, disabled, loading = false, title, children, onClick }) => {
  return (
    <button
      className={`${className} ${loading ? "loading" : ""} ${disabled ? "button-disabled" : null}`}
      type={type ? type : ButtonType.BUTTON}
      disabled={disabled}
      title={title}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
