import Swarm from "connection/Swarm";
import React, { useCallback, useEffect, useState } from "react";
import Log from "./Log";

const isReady = () =>
  typeof window !== "undefined" && typeof window.grecaptcha !== "undefined" && typeof window.grecaptcha.render === "function";

let intervalId: any;

export enum ReCaptchaTheme {
  DARK = "dark",
  LIGHT = "light"
}
export enum ReCaptchaSize {
  NORMAL = "normal",
  COMPACT = "compact"
}

type ReCaptchaSizeType = ReCaptchaSize.COMPACT | ReCaptchaSize.NORMAL;
type ReCaptchaThemeType = ReCaptchaTheme.DARK | ReCaptchaTheme.LIGHT;

interface IPropTypes extends IFormControl {
  refresh: boolean;
  theme: ReCaptchaThemeType;
  size: ReCaptchaSizeType;
  onChange: (s: string) => void;
}

const ReCaptcha: React.FunctionComponent<IPropTypes> = ({ error, theme, refresh, size, onChange }) => {
  const [siteKey, setSiteKey] = useState<string | null>(null);

  const reset = () => {
    if (isReady()) {
      try {
        window.grecaptcha.reset();
      } catch (e) {}
    }
  };

  const render = useCallback(() => {
    window.grecaptcha.render("g-recaptcha", {
      sitekey: siteKey,
      callback: (token: string) => onChange && onChange(token),
      theme: theme || ReCaptchaTheme.DARK,
      size: size || ReCaptchaSize.NORMAL,
      "error-callback": reset,
      "expired-callback": reset
    });
  }, [siteKey, theme, size, onChange]);

  useEffect(() => {
    if (siteKey !== null) {
      const checkReadyState = () => {
        if (siteKey !== null && isReady()) {
          clearInterval(intervalId);
          render();
        }
      };

      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js?render=explicit";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      intervalId = setInterval(checkReadyState, 100);
    }
  }, [siteKey]);

  useEffect(() => {
    Swarm.get({}, "recaptcha_sitekey")
      .then(({ result }) => {
        setSiteKey(result);
      })
      .catch(failResponse => {
        Log.error(failResponse);
      });
  }, []);

  useEffect(() => {
    reset();
  }, [error, refresh]);

  return (
    <div className="input-control-holder-bc recaptcha">
      <div className="recaptcha-control-bc" id="g-recaptcha" />
      {error && <div className="input-control-error-message-bc">{error}</div>}
    </div>
  );
};

export default React.memo(ReCaptcha);
