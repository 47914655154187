/**
 * Helper functions
 *
 */

/**
 * @function getQueryValue
 * @param {string|null} [url] to get the param form given string default is current location
 * @description Parses provided query params, finds the required value in accordance with the provided name and returns it
 * @returns {string}
 */
export const getQueryParams = (url: string | null = null): Dictionary<string> => {
  let params: Dictionary<string> = {};
  (url || window.location.href).replace(/[?&]+([^=&]+)=([^&]*)/gi, (match: string, key: string, value: string) => {
    params[key] = value;
    return value;
  });

  return params;
};

export const generateQueryParams = (params: Dictionary<string>) => {
  return Object.entries(params)
    .reduce<string[]>((acc, [key, value]) => [...acc, `${key}=${value}`], [])
    .join("&");
};

/**
 * @function getCurrentTimeRoundedByMinutes
 * @param {int} minutes - time in minutes, which should be rounded returned value
 * @description calculate current time rounded by provided minutes
 * @returns {string}
 */
export const getCurrentTimeRoundedByMinutes = (minutes: number): string => {
  const now = new Date();
  now.setMinutes((Math.round(now.getMinutes() / minutes) * minutes) % 60);
  now.setSeconds(0);
  now.setMilliseconds(0);
  return now.getTime().toString();
};

/**
 * @function MergeRecursive
 * @description merges 2 objects recursively
 * @param {Object} to destination object
 * @param {Object} from source object
 * @return {Object} returns changed destination object
 */
export const mergeRecursive = (to: any, from: any) => {
  let p;
  for (p in from) {
    if (from.hasOwnProperty(p)) {
      try {
        if (from[p].constructor === Object) {
          if (from[p]["@replace"] === true) {
            //replace field instead of merging if specified
            to[p] = from[p];
            delete to[p]["@replace"];
          } else {
            to[p] = mergeRecursive(to[p], from[p]);
          }
        } else {
          to[p] = from[p];
        }
      } catch (e) {
        to[p] = from[p];
      }
    }
  }
  return to;
};

/**
 * @function getFlattenArray
 * @description Resulting flatten array from given array with nestings
 * @param {Array} arr
 * @param {Number} depth
 * @return {Array}
 */
export const getFlattenArray = (arr: any[], depth: number = Number.MAX_SAFE_INTEGER): any[] => {
  const len = arr.length;
  let flattened: any[] = [];
  let i = 0;
  while (i < len) {
    if (i in arr) {
      const el = arr[i];
      if (Array.isArray(el) && depth > 0) flattened = flattened.concat(getFlattenArray(el, depth - 1));
      else flattened.push(el);
    }
    i++;
  }
  return flattened;
};

/**
 *
 * @param {string | number} string
 * @param {number} length
 * @param {string} padString
 * @return {string}
 */
export const padStart = (string: string, length: number, padString: string = ""): string => {
  length = length >> 0;
  if (string.length >= length) {
    return String(string);
  } else {
    length = length - string.length;
    let padStringResult = "";
    if (length >= padString.length) {
      while (length > 0) {
        padStringResult += padString;
        length--;
      }
    }
    return padStringResult + String(string);
  }
};

/**
 * @function objectToArray
 * @description Converts object to array
 * @param {Object} obj Object to convert
 * @param {String} addKeyNameAsProperty if defined, object key names will be added to array elements with property of this name
 * @returns {Array} array
 */
export const objectToArray = (obj: any, addKeyNameAsProperty: string = "") => {
  const arr = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (addKeyNameAsProperty) {
        obj[key][addKeyNameAsProperty] = key;
      }
      arr.push(obj[key]);
    }
  }
  return arr;
};

/**
 * @function orderSorting
 * @description  compares 2 items based on their "order" field
 * @param {Object} a first item
 * @param {Object} b second item
 */
export const orderSorting = (a: Dictionary<any>, b: Dictionary<any>) => {
  return a.order - b.order;
};

/**
 * Generates unique id
 * https://gist.github.com/jed/982883
 */
export const uid = (a: any = null): string => {
  return a ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16) : (1e7 + -1e3 + -4e3 + -8e3 + -1e11).toString().replace(/[018]/g, uid);
};

/**
 * Calculate age from given date
 * @param {string|null} date
 * @returns {number}
 */
export const calculateAge = (date: string | null): number => {
  if (typeof date === "string") {
    return Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000 / 60 / 60 / 24 / 365.25);
  }
  return 0;
};

/**
 * Checks if value is an object
 * @param val - any value
 * @returns {boolean}
 */
export const isObject = (val: Dictionary<any>) => Object.prototype.toString.call(val) === "[object Object]";

/**
 * Converts object map, using specified item property value as key
 * @param {Object} obj -  the object
 * @param {string} itemPropertyNameToUseAsKey - property name to use as key
 * @returns {Object} created map object
 */
export const createMapFromObjItems = (obj: Dictionary<Dictionary<any>>, itemPropertyNameToUseAsKey: string) => {
  if (!isObject(obj)) {
    console.warn("createMapFromObjItems - not an object:", obj);
    return obj;
  }

  const ret: Dictionary<any> = {};
  for (let item in obj) {
    if (obj.hasOwnProperty(item)) {
      ret[obj[item][itemPropertyNameToUseAsKey]] = item;
    }
  }

  return ret;
};

export const getClosestElement = (target: EventTarget, elementName: string): EventTarget | null => {
  const targetName = (target as Element).tagName;

  if (!targetName) {
    return null;
  }

  if (targetName.toLowerCase() === elementName.toLowerCase()) {
    return target;
  }

  return getClosestElement((target as Element).parentNode as EventTarget, elementName);
};

export const isEmpty = (value: any) => {
  if (value === null || value === undefined) {
    return true;
  } else if (Array.isArray(value)) {
    return value.length === 0;
  } else if (typeof value === "string") {
    return value.trim().length === 0;
  } else if (typeof value === "number") {
    return value.toString().trim().length === 0;
  } else if (typeof value === "boolean") {
    return !value;
  } else if (typeof value === "object") {
    return Object.keys(value).length === 0;
  }
  return false;
};

export const removeNullValuesFromObject = (object: Dictionary<any>) => {
  const nullFreeObject: Dictionary<any> = {};
  for (let x in object) {
    if (object.hasOwnProperty(x) && object[x] !== null) {
      nullFreeObject[x] = object[x];
    }
  }
  return nullFreeObject;
};

export const getStartOfDay = (timestamp: number) => new Date(timestamp * 1000).setHours(0, 0, 0, 0) / 1000;

export function getMapProp<T, K>(map: Map<T, K>, prop: T, defaultReturn: K) {
  return map.get(prop) || defaultReturn;
}

export const readFileData = (file: File) => {
  return new Promise<FileData>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = (event: ProgressEvent<FileReader>) => {
      if (event.target) {
        resolve({
          data: event.target.result,
          name: file.name
        });
      } else {
        reject();
      }
    };
    fileReader.readAsDataURL(file);
  });
};

export const numberWithCommas = (num: number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const dateTimeToSeconds = (dateTime: string) => {
  const date = new Date(dateTime);
  return Math.round(date.getTime() / 1000) - date.getTimezoneOffset() * 60;
};
