import hash from "object-hash";
import Log from "helpers/Log";
import Request from "./Request";
import WS from "./WS";

class BaseSocket {
  private _url: string = "";

  protected webSocket: WS | null = null;
  protected requests: Map<string, Request> = new Map();
  protected subIdRidMap: Map<string, string> = new Map();
  protected unsubIdRidMap: Map<string, string> = new Map();
  protected queue: CallbackFunction[] = [];

  protected readonly maxAttemptsCount: number = 5;

  set url(value: string) {
    this._url = value;
  }

  protected static generateRid(params: Dictionary<any>): string {
    return hash(params, { unorderedArrays: true });
  }

  protected openConnection(onOpen: CallbackFunction, onClose: CallbackFunction, onError: CallbackFunction, onMessage: CallbackFunction) {
    this.webSocket = new WS(this._url, onMessage, onOpen, onClose, onError);
  }

  protected closeConnection(code: number, reason: string) {
    if (this.webSocket) {
      this.webSocket.close(code, reason);
      this.webSocket = null;
    }
  }

  protected handleInternalErrors(err: Error | { message: string }) {
    Log.error(err.message);
  }

  protected enqueue(func: CallbackFunction) {
    this.queue.push(func);
  }

  protected dequeueAll() {
    this.queue.forEach(cb => cb());
    this.queue = [];
  }
}

export default BaseSocket;
