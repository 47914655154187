import { debounceReduce } from "connection/helpers";
import Subscription from "connection/Subscription";
import AuthData from "services/authData";
import Recaptcha from "connection/Recaptcha";
import Log from "../helpers/Log";
import BaseSocket from "./BaseSocket";
import Request from "./Request";

enum SwarmCodes {
  SUCCESS = 0,
  FAILURE = 2,
  SESSION_LOST = 5,
  NEED_TO_LOGIN = 12
}

enum Errors {
  TIMEOUT_CANCELED = "Restore session cancelled. Most likely the websocket connection closed unexpectedly.",
  UNABLE_TO_OPEN_SESSION = "Unable to open session. Giving up.",
  UNABLE_TO_RESTORE_SESSION = "Couldn't restore session",
  UNABLE_TO_REOPEN_WEBSOCKET = "Couldn't reestablish websocket connection. Giving up.",
  UNINITIALIZED = "Swarm instance not initialized (Swarm.init)"
}

const AllowedCommands: Dictionary<string> = {
  request_session: "request_session",
  validate_recaptcha: "validate_recaptcha"
};

export class Swarm extends BaseSocket {
  private static readonly Codes = SwarmCodes;
  private static readonly Errors = Errors;
  private static readonly AllowedCommands = AllowedCommands;

  private connectionParams: IConnectionParams | null = null;
  private wsCloseResolver: PromiseResolverFunction | null = null;
  private session: ISwarmSessionResponse | null = null;

  private retryAttempts = {
    socket: 0,
    session: 0,
    delay: () => {
      this.retryAttempts.cancel({ message: Swarm.Errors.TIMEOUT_CANCELED });
      return new Promise((resolve, reject) => {
        setTimeout(resolve, 5000);
        this.retryAttempts.cancel = reject;
      });
    },
    cancel(reason?: { message: string }) {}
  };

  protected handleInternalErrors(err: Error | { message: string }) {
    super.handleInternalErrors(err);

    switch (err.message) {
      case Swarm.Errors.UNABLE_TO_OPEN_SESSION:
      case Swarm.Errors.UNABLE_TO_RESTORE_SESSION:
        if (this.webSocket) {
          this.closeConnection(4000, err.message);
        }
        break;
      default:
    }
  }

  public connect(url: IConnectionParams["url"], sessionParameters: IConnectionParams["session"]) {
    this.connectionParams = { url, session: sessionParameters };
    this.url = url;
    this.openConnection(this.onopen, this.onclose, this.onerror, this.onmessage);
  }

  public async getSession() {
    if (this.session) return Promise.resolve(Object.assign({}, this.session));

    return new Promise(resolve => this.enqueue(() => resolve(Object.assign({}, this.session))));
  }

  public get(params: Dictionary<any>, command: string = "get"): Promise<any> {
    if (this.webSocket) {
      let rid = BaseSocket.generateRid(params);

      if (this.requests.has(rid)) {
        rid = BaseSocket.generateRid({ ...params, _$r: Math.random() });
      }

      const body = { command, params, rid };
      const request = new Request(rid, body);

      this.requests.set(rid, request);
      this.sendRequest(request.body);

      return new Promise((resolve, reject) => request.addHandler(resolve, reject));
    } else {
      return Promise.reject({ msg: "Websocket connection closed." });
    }
  }

  public subscribe(
    params: Dictionary<any>,
    onUpdate: CallbackFunction,
    onFailure: CallbackFunction = () => {},
    mergeUpdateData: boolean = true
  ): string {
    if (this.webSocket) {
      params.subscribe = true;

      const rid = BaseSocket.generateRid(params);

      const cachedRequest = this.requests.get(rid);
      if (!cachedRequest) {
        const body = { command: "get", params, rid };
        const request = new Request(rid, body, true, mergeUpdateData);
        const subscription = request.addHandler(onUpdate, onFailure);

        this.requests.set(rid, request);
        this.unsubIdRidMap.set(subscription.id, rid);

        this.sendRequest(request.body);
        return subscription.id;
      }

      const subscription: Subscription = cachedRequest.addHandler(onUpdate, onFailure);
      this.unsubIdRidMap.set(subscription.id, rid);

      return subscription.id;
    } else {
      onFailure({ msg: "Websocket connection closed." });
      return "";
    }
  }

  public login(user: { username: string; password: string }, additionalParams: Dictionary<any> = {}): Promise<ISwarmAuthData> {
    return this.get({ ...user, ...additionalParams, encrypted_token: true }, "login");
  }

  public logout() {
    return this.get({}, "logout");
  }

  public restore(sessionParams: Partial<IConnectionParams["session"]>) {
    if (!this.connectionParams) throw Error(Swarm.Errors.UNINITIALIZED);

    this.connectionParams.session = { ...this.connectionParams.session, ...sessionParams };
    this.reopenConnectionAndRestoreSession();
  }

  public disconnect(code: number, reason: string): Promise<any> {
    return new Promise(resolve => {
      if (this.webSocket) {
        this.wsCloseResolver = resolve;
        this.closeConnection(code, reason);
      } else {
        resolve();
      }
    });
  }

  public validateRecaptchaAction(action: string) {
    if (this.session) {
      if (this.session.recaptcha_enabled && this.session.recaptcha_version === 3) {
        return this.getTokenAndValidate(action);
      } else {
        return Promise.reject("Recaptcha not enabled or version is other than 3");
      }
    }

    if (action === Recaptcha.Actions.session_opened) {
      return this.getTokenAndValidate(action);
    }

    return new Promise(resolve => {
      this.enqueue(() => this.validateRecaptchaAction(action).then(resolve));
    });
  }

  private async getTokenAndValidate(action: string) {
    const token = await Recaptcha.getToken(action);
    return this.get({ g_recaptcha_response: token, action }, Swarm.AllowedCommands.validate_recaptcha);
  }

  private sendRequest(requestBody: Dictionary<any>) {
    if (this.webSocket) {
      if (this.session || Swarm.AllowedCommands[requestBody.command]) {
        this.webSocket.send(requestBody);
      } else {
        this.enqueue(() => this.webSocket!.send(requestBody));
      }
    }
  }

  private removeSubscription(subIds: string[]) {
    if (subIds.length > 1) {
      this.get({ subids: subIds }, "unsubscribe_bulk").catch(Log.warning);
    } else if (subIds.length === 1) {
      this.get({ subid: subIds[0] }, "unsubscribe").catch(Log.warning);
    }
  }

  private _unsubscribe = debounceReduce(
    (unsubIds: Set<string>) => {
      try {
        const subIds: string[] = [];

        // Grouping requests that have a sub id and can be unsubscribed from
        unsubIds.forEach(unsubId => {
          const rid = this.unsubIdRidMap.get(unsubId);

          if (rid) {
            const request = this.requests.get(rid);

            if (request) {
              if (request.requestHandlers.size === 0 && request.subId) {
                subIds.push(request.subId);
                this.requests.delete(rid);
              }
            }
          }
        });

        this.removeSubscription(subIds);
      } catch (e) {
        this.handleInternalErrors(e);
      }
    },
    100,
    (idBatch = new Set<string>(), id: string[]) => new Set([...idBatch, ...id])
  );

  public unsubscribe(unsubId: string) {
    if (!unsubId) {
      return Log.warning("Can't unsubscribe without a subid.");
    }

    const rid = this.unsubIdRidMap.get(unsubId);

    if (rid) {
      const request = this.requests.get(rid);

      if (request) {
        request.removeHandler(unsubId);

        this._unsubscribe(unsubId);
      }
    }
  }

  private async openSession(): Promise<ISwarmSessionResponse> {
    if (!this.connectionParams) throw Error(Swarm.Errors.UNINITIALIZED);

    const sessionParams = { ...this.connectionParams.session };

    // TODO: make session request configurable; handle everCookie, 'sendSourceInRequestSession' & 'sendTerminalIdlInRequestSession' cases

    const storedRecaptchaKey = Recaptcha.getStoredData();
    if (storedRecaptchaKey) {
      await Recaptcha.init(storedRecaptchaKey);
      const token = await Recaptcha.getToken(Recaptcha.Actions.session_opened);
      if (token) {
        sessionParams.g_recaptcha_response = token;
      }
    }

    const sessionData: ISwarmSessionResponse = await this.get(sessionParams, Swarm.AllowedCommands.request_session);
    Log.success("Session successfully opened");

    const { recaptcha_enabled, recaptcha_version, site_key } = sessionData;

    if (recaptcha_enabled && recaptcha_version === 3) {
      if (!sessionParams.g_recaptcha_response) {
        await Recaptcha.init(site_key);
        await this.validateRecaptchaAction(Recaptcha.Actions.session_opened);
      }

      Recaptcha.storeData(site_key);
    } else {
      Recaptcha.deleteStoredData();
    }

    return sessionData;
  }

  private onopen = async (): Promise<any> => {
    try {
      this.session = await this.openSession();
      this.dequeueAll();

      this.retryAttempts.session = 0;
    } catch (e) {
      Log.warning(e);

      if (this.retryAttempts.session < this.maxAttemptsCount) {
        this.retryAttempts.session += 1;
        Log.warning(`Retrying to open session. Failed attempts: ${this.retryAttempts.session}.`);
        try {
          await this.retryAttempts.delay();
          return this.onopen();
        } catch (e) {
          // Handling errors inside the function as it is only used as a callback
          this.handleInternalErrors(e);
        }
      } else {
        // Handling errors inside the function as it is only used as a callback
        this.handleInternalErrors(Error(Swarm.Errors.UNABLE_TO_OPEN_SESSION));
      }
    }
  };

  public restoreLogin(loginAuthData: ISwarmAuthData) {
    return loginAuthData.jwe_token
      ? this.get({ jwe_token: loginAuthData.jwe_token, auth_token: loginAuthData.auth_token }, "login_encrypted")
      : this.get({ user_id: loginAuthData.user_id, auth_token: loginAuthData.auth_token }, "restore_login");
  }

  private async restoreSession(): Promise<void> {
    try {
      this.retryAttempts.session += 1;
      Log.warning(`Trying to restore session. Attempt #${this.retryAttempts.session}.`);

      this.session = await this.openSession();

      const authData = AuthData.get();
      if (authData) {
        try {
          await this.restoreLogin(authData);
        } catch (e) {}
      }

      this.requests.forEach(request => {
        this.webSocket!.send(request.body);
      });

      this.retryAttempts.session = 0;
    } catch (e) {
      if (this.retryAttempts.session < this.maxAttemptsCount) {
        await this.retryAttempts.delay();
        return this.restoreSession();
      } else {
        throw Error(Swarm.Errors.UNABLE_TO_RESTORE_SESSION);
      }
    }
  }

  private async restoreSessionAndDequeue() {
    try {
      this.retryAttempts.socket = 0;

      await this.restoreSession();
      this.dequeueAll();
    } catch (e) {
      this.handleInternalErrors(e);
    }
  }

  private reopenConnectionAndRestoreSession() {
    this.openConnection(() => this.restoreSessionAndDequeue(), this.onclose, this.onerror, this.onmessage);
  }

  private onclose = (code: number) => {
    this.session = null;
    // No web socket instance (webSocket === null) or event code 4000 signifies that the connection was closed by the app
    if (!this.webSocket) {
      if (this.wsCloseResolver) {
        this.wsCloseResolver();
        this.wsCloseResolver = null;
      }
      return;
    }

    if (code !== 4000) {
      if (!this.connectionParams) throw Error(Swarm.Errors.UNINITIALIZED);

      if (this.retryAttempts.socket < this.maxAttemptsCount) {
        this.retryAttempts.cancel({ message: Swarm.Errors.TIMEOUT_CANCELED });
        this.retryAttempts.socket += 1;
        this.retryAttempts.session = 0;
        Log.warning(`Establishing new websocket connection. Attempt #${this.retryAttempts.socket}. Restore session attempts reset.`);
        this.reopenConnectionAndRestoreSession();
      } else {
        // Handling errors inside the function as it is only used as a callback
        this.handleInternalErrors(Error(Swarm.Errors.UNABLE_TO_REOPEN_WEBSOCKET));
      }
    }
  };

  private onmessage = async (message: string) => {
    const response: IResponse = JSON.parse(message);
    const { rid } = response;

    if (rid !== "0") {
      const request = this.requests.get(rid);
      if (!request) return;

      // Message response
      switch (response.code) {
        case Swarm.Codes.SUCCESS:
          if (request.subscribe) {
            const { data, subid } = response.data;

            request.subId = subid;

            if (request.requestHandlers.size === 0) {
              this.requests.delete(rid);
              this.removeSubscription([subid]);
            } else {
              request.setData(data);
              this.subIdRidMap.set(subid, rid);
            }
          }
          // Case when request is not subscription
          else {
            request.setData(response.data);
            this.requests.delete(rid);
          }
          break;

        case Swarm.Codes.NEED_TO_LOGIN:
        case Swarm.Codes.FAILURE:
          request.failHandlers(response);
          this.requests.delete(rid);
          break;

        case Swarm.Codes.SESSION_LOST:
          this.session = null;
          this.enqueue(() => this.webSocket!.send(request.body));
          await this.restoreSessionAndDequeue();
          break;

        default:
          request.failHandlers(response);
          this.requests.delete(rid);
      }
    } else {
      // RequestHandler update
      const { data } = response;
      for (let subId in data) {
        if (data.hasOwnProperty(subId)) {
          const rid = this.subIdRidMap.get(subId);
          if (rid) {
            const request = this.requests.get(rid);
            if (request) {
              request.updateData(data[subId]);
            }
          }
        }
      }
    }
  };

  private onerror = (reason: string) => {
    if (this.webSocket) {
      this.session = null; // Remove session in order for requests to be queued
      this.closeConnection(4001, reason);
    }
  };
}

export default new Swarm();

export enum SessionSource {
  Html5 = 42,
  Mobile = 4,
  Android = 16,
  IOS = 17,
  Betshop = 98,
  Terminal = 99,
  SMS = 100
}

type PromiseResolverFunction = (value?: unknown) => void;

interface IConnectionParams {
  url: string;
  session: { language: string; site_id: number; g_recaptcha_response?: string; source: SessionSource };
}

interface IResponse {
  rid: string;
  code: SwarmCodes;
  data: Dictionary<any>;
}
