import CMSBase from "./CMSBase";

class CMSLoader extends CMSBase {
  private widgets: Dictionary<any> = {};

  public getWidget<T>(id: string = "sidebar-1", language: string, isCacheable: boolean = true): Promise<T> {
    //const url = WP_URL + '?base_host=' + wpBaseHost +  addHttpsFlag(WP_URL) + '&lang=' + Config.env.lang + '&json=widgets/get_sidebar&sidebar_id=' + sidebarId + countryQuery;

    if (this.widgets[id]) {
      return Promise.resolve(this.widgets[id]);
    }

    const urlParams: Dictionary<string> = {};
    urlParams["base_host"] = "vivarosport";
    urlParams["ssl"] = "1";
    urlParams["lang"] = language;
    urlParams["json"] = "widgets/get_sidebar";
    urlParams["sidebar_id"] = id;
    urlParams["country"] = "AM";

    return CMSLoader.loadData<T>("/json", urlParams).then(response => {
      if (isCacheable) {
        this.widgets[id] = response;
      }
      return response;
    });
  }
}

export default new CMSLoader();
